import Api from "./Api";

const store = (payload) => {
  return Api.postWithFormData({
    endpoint: "/file/image/store",
    data: payload,
  });
};

const remove = (payload) => {
  return Api.postWithFormData({
    endpoint: "/file/image/remove",
    data: payload,
  });
};

export default {
  store,
  remove,
};
